<template>
    <div>
        Refreshing...
    </div>
</template>

<script>
import { apiService } from '@/services'
import { mapActions } from 'vuex'
export default {
  name: 'Refresh',
  created () {
    this.checkToken()
  },
  methods: {
    ...mapActions({ showError: 'error/show' }),
    checkToken () {
      apiService.getAll('/isloggedin').then(r => {
        this.$route.replace('dashboard')
      }).catch(error => {
        this.showError(error)
      })
    }
  }
}
</script>
